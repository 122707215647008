import { graphql } from "gatsby";
import React from "react";
import Layout from "../components/Layout/Layout";
import { Flex } from "../components/Flex/Flex";
import { getImage } from "gatsby-plugin-image";
import { OurWorkListItem } from "../components/OurWork/OurWorkListItem";
import { Hero } from "../components/Hero/Hero";
import { Section } from "../components/Section/Section";



const OurWorkList = ({ data, pageContext, location }: any) => {

	const lang = pageContext.lang;
	const allGalleries = data.allGallery.nodes;

	console.log("all galleries",allGalleries)

	const headerData = pageContext.pageData.header;
	const titleData = headerData.title[lang];
	const descriptionData = headerData.description[lang];

	const mapLangGallery = allGalleries.map((t: any, i: number) => {
		const mapDataObject = t.locales.find(
			(locale: any) => locale.iso === lang
		);

		const titleData: string = mapDataObject.title;
		const slugData: string = mapDataObject.value;
		//const imageData: any = getImage(t.imageFile);
		
		return (
			<OurWorkListItem
				key = {t.uid}
				title={titleData}
				image={t.imageFile.publicURL}
				imageAlt={titleData}
				slug={slugData}
				buttonText={lang === "es" ? "Ver projecto" : "View project"}
			/>
		);
	});

	return (
		<Layout
			department={data.department}
			location={location}
			lang={lang}
			otherLang={pageContext.otherLang}
			defLang={pageContext.defLang}
			metaInfo={pageContext.metaInfo}
		>
			<div className="page-wrapper">
				<Hero title={titleData} description={descriptionData}/>
				<Section className="no-padding-small">
					<Flex options={{ alignH: true, alignV: true, gap:true}}>
						{mapLangGallery}
					</Flex>
				</Section>
			</div>
		</Layout>
	);
};

export const query = graphql`
  {
    allGallery {
      nodes {
        uid
        position
        locales {
          description
          iso
          title
          value
        }
        imageFile {
			childImageSharp {
			  gatsbyImageData(formats: JPG)
			}
			publicURL
		  }
      }
    }
  }
`

export default OurWorkList;
