import React from "react";

import * as style from "./ourwork.module.css";
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

interface IBOurWorkListItem {
	title: string;
	image: any;
	imageAlt: string;
	slug: string;
	buttonText: string
}

export function OurWorkListItem({
	title,
	image,
	imageAlt,
	slug,
	buttonText
}: IBOurWorkListItem) {


	return (
		<div className={style.ourWorkListItem} >
			{BackgroundImage({ image, imageAlt,slug })}
			<Link to={slug} className={style.content}>
				<h2>{title}</h2>
				<p className={style.ctaSingleItem} >
					{buttonText}
				</p>
			</Link>
		</div>
	);
}

function BackgroundImage({ image, imageAlt,slug }: any) {
	if (image) {
		return <img src={image} alt={imageAlt} className={style.projectImg} />
		// return <GatsbyImage image={image} alt={imageAlt} />
	} else {
		return <div className={style.backgroundReplacer}></div>
	}
}
