import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Navigation, Autoplay } from "swiper";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import image1 from "../../images/heroSlider/apartment-with-kichen.jpg";
import image2 from "../../images/heroSlider/bathroom-retro-style.jpg";
import image3 from "../../images/heroSlider/wooden-stairs-and-fence.jpg";

import "swiper/css/effect-fade";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import * as style from "./hero.module.css";

import graphicIcon from "../../images/branding/graphic-cgr-icon.svg";
import { Link } from "gatsby";

export function Hero({ title, description }: any) {

	return (
		<div className={style.hero}>
			<div className={style.graphic}>
				<img src={graphicIcon} alt="" />
			</div>
			<h1>{title}</h1>
			<p>{description}</p>
		</div>
	);
}
